import React, { useState, useEffect } from 'react';
import { getCleanedDomainName } from './urlFetch';

export function HeaderIndividual() {
  const isAdmin = window.location.pathname.includes('/admin');

    // Fetching Holiday Categories
    const [holidayCategories, setHolidayCategories] = useState([]);

    useEffect(() => {
        const interestsUrl = `${process.env.REACT_APP_API_ENDPOINT}interest_types?data=1`;
        fetch(interestsUrl)
            .then(response => response.json())
            .then(data => setHolidayCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Holiday Categories
    const [statesFetch, setStatesFetch] = useState([]);

    useEffect(() => {
        const statesUrl = `${process.env.REACT_APP_API_ENDPOINT}cities?states=all`;
        fetch(statesUrl)
            .then(response => response.json())
            .then(data => setStatesFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Hotel Categories
    const [hotelCategories, setHotelCategories] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_categories`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setHotelCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Hotel Groups
    const [groupsFetch, setGroupsFetch] = useState([]);

    useEffect(() => {
        const groupsUrl = `${process.env.REACT_APP_API_ENDPOINT}hotel_groups`;
        fetch(groupsUrl)
            .then(response => response.json())
            .then(data => setGroupsFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Product Categories
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
        const categoryUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_categories`;
        fetch(categoryUrl)
            .then(response => response.json())
            .then(data => setProductCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Product Categories
    const [materialsFetch, setMaterialsFetch] = useState([]);

    useEffect(() => {
        const materialsUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_materials?material=all`;
        fetch(materialsUrl)
            .then(response => response.json())
            .then(data => setMaterialsFetch(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Homam Categories
    const [homamCategories, setHomamCategories] = useState([]);

    useEffect(() => {
        const homamCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=homam`;
        fetch(homamCategoryUrl)
            .then(response => response.json())
            .then(data => setHomamCategories(data))
            .catch(error => console.log(error));
    }, []);

    // Fetching Astrology Categories
    const [astrologyCategories, setAstrologyCategories] = useState([]);

    useEffect(() => {
        const astrologyCategoryUrl = `${process.env.REACT_APP_API_ENDPOINT}homam_listings?cat=astrology`;
        fetch(astrologyCategoryUrl)
            .then(response => response.json())
            .then(data => setAstrologyCategories(data))
            .catch(error => console.log(error));
    }, []);


    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
      setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const [microData, setMicroData] = useState({
      name: '',
      domain: '',
      place: '',
      location: '',
      analytics: '',
    });
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}`;

  useEffect(() => {
    if(domainName){
        console.log('API URL:', apiUrl);
        fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
            console.log('API Response:', data);
            setMicroData(data[0]); // Assuming data is an array with a single item
        })
        .catch(error => console.error('API Error:', error));
    }
    
  }, [domainName, apiUrl]);

  //console.log('Name:', microData.name);
  //console.log('Domain:', microData.domain);

  const tourPackageUrlPart = microData.name.toLowerCase().replace(/\s+/g, '-');

  const googleTagManagerScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${microData.analytics}');
  `;

  const [isMenuActive, setMenuActive] = useState(false);

  const handleMenuButtonClick = () => {
    setMenuActive(!isMenuActive);
  };

  return (
    <>
    {microData.ad_img ? (
    <center>
      <a href="https://wa.me/919892002006">
        <img class="top_banner py-10" src={`https://api.blessingsonthenet.com/uploads/microsite/banners/${microData.ad_img}`} />
      </a>
    </center>
    ) : ''}
    <header data-add-bg="bg-dark-1" class="header bg-green js-header" data-x="header" data-x-toggle="is-menu-opened">

      {/* Google tag (gtag.js) */}
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${microData.analytics}`}></script>
      <script dangerouslySetInnerHTML={{ __html: googleTagManagerScript }} />

      <div data-anim="fade" class="header__container px-30 sm:px-20">
        <div class="row justify-between items-center">
          
          <div class="col-auto">
            <div class="d-flex items-center">
              <a href="/" class="header-logo mr-20 text-22" data-x="header-logo" data-x-toggle="is-logo-dark">
                {domainName && domainName.charAt(0).toUpperCase() + domainName.slice(1)}
                {/*
                <img src="https://blessingsonthenet.com/img/Nirvana-Enterprises-Logo.png" alt="logo icon" />
                <img src="https://blessingsonthenet.com/img/Nirvana-Enterprises-Logo.png" alt="logo icon" />
                */}
              </a>


              <div className={`header-menu ${isMenuActive ? 'is-menu-active' : ''}`} data-x="mobile-menu" data-x-toggle={isMenuActive ? 'is-menu-active' : ''} style={{ position: 'absolute', right: '150px' }}>
                <div class="mobile-overlay"></div>

                <div class="header-menu__content">
                  <div class="mobile-bg js-mobile-bg"></div>

                  <div class="menu js-navList">
                    <ul class="menu__nav text-white -is-active">

                      <li>
                        <a data-barba href="/">
                          <span class="mr-10">Home</span>
                        </a>

                      </li>
                      
                      <li>
                        <a href={domainName === 'shirdisaitemple.com' ? `/shirdi-tour-package/${tourPackageUrlPart}` : `/tour-package/${tourPackageUrlPart}`}>
                          {microData.place} Tour Packages
                        </a>
                      </li>

                      <li>
                        <a href="/show-idols/">
                          Online Shopping
                        </a>
                      </li>

                      <li>
                        <a href={domainName === 'shirdisaitemple.com' ? `/shirdi-hotels/${microData.place.toLowerCase().replace(/\s+/g, '-')}/${microData.location}/${tourPackageUrlPart}` : `/hotels/${microData.place.toLowerCase().replace(/\s+/g, '-')}/${microData.location}/${tourPackageUrlPart}`}>
                          {microData.place} Hotels
                        </a>
                      </li>    

                      <li>
                        <a href="/indian-temples/">
                          India Temples
                        </a>
                      </li>                  

                    </ul>
                  </div>

                  <div class="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-auto">
            <div class="d-flex items-center">
              <div class="d-none xl:d-flex x-gap-20 items-center pl-30 text-white" data-x="header-mobile-icons" data-x-toggle="text-white">
                <div><button class="d-flex items-center icon-menu text-inherit text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu" onClick={handleMenuButtonClick}></button></div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </header>
    </>
  );
}